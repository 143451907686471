<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 5H17.8826L12.4445 13.4592L11.454 15H13.2857H21V13H15.1173L20.5554 4.54076L21.5459 3H19.7142H12V5ZM4.00003 14H7.3581L4.00432 19.4778L3.07239 21H4.85717H10V19H6.64195L9.99574 13.5222L10.9277 12H9.14288H4.00003V14Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, default: 24 },
  },
};
</script>
