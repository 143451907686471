<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 2C8.44772 2 8 2.44772 8 3H6C4.89543 3 4 3.89543 4 5V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V17.3955L18.0241 20.0001L6 20V5H8C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5H18V10.1045L20 7.46812V5C20 3.89543 19.1046 3 18 3H16C16 2.44772 15.5523 2 15 2H9Z"
      :fill="fill"
    />
    <path
      d="M9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10H15C15.5523 10 16 9.55228 16 9C16 8.44772 15.5523 8 15 8H9Z"
      :fill="fill"
    />
    <path
      d="M8 13C8 12.4477 8.44772 12 9 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H9C8.44772 14 8 13.5523 8 13Z"
      :fill="fill"
    />
    <path
      d="M9 16C8.44772 16 8 16.4477 8 17C8 17.5523 8.44772 18 9 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H9Z"
      :fill="fill"
    />
    <path
      d="M21.5911 10.1934C22.0366 10.5199 22.133 11.1457 21.8066 11.5911L17.4793 17.4955C17.0762 18.0455 16.2749 18.1 15.8011 17.6096L13.2809 15.002C12.8971 14.6049 12.9079 13.9718 13.3051 13.588C13.7022 13.2042 14.3353 13.215 14.7191 13.6121L16.4972 15.4521L20.1934 10.4089C20.5199 9.9634 21.1457 9.86695 21.5911 10.1934Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
