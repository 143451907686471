<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3H3H14C15.1046 3 16 3.89543 16 5H16.7639C17.9002 5 18.939 5.64201 19.4472 6.65836L21.8944 11.5528L22 11.7639V12V13.5V17C22 18.1046 21.1046 19 20 19H19.7509C19.2611 20.1745 18.102 21 16.75 21C15.398 21 14.2389 20.1745 13.7491 19H11.2509C10.7611 20.1745 9.60198 21 8.25 21C6.89802 21 5.73886 20.1745 5.24908 19H4C2.89543 19 2 18.1046 2 17V4V3ZM11.413 17H13.587C13.9255 15.5666 15.2132 14.5 16.75 14.5C18.2868 14.5 19.5745 15.5666 19.913 17H20V13.5V13H17C15.3431 13 14 11.6569 14 10V6V5H4V17H5.08697C5.42555 15.5666 6.71321 14.5 8.25 14.5C9.78679 14.5 11.0745 15.5666 11.413 17ZM19.382 11H17C16.4477 11 16 10.5523 16 10V7H16.7639C17.1427 7 17.489 7.214 17.6584 7.55279L19.382 11ZM9.5 17.75C9.5 18.4404 8.94036 19 8.25 19C7.55964 19 7 18.4404 7 17.75C7 17.0596 7.55964 16.5 8.25 16.5C8.94036 16.5 9.5 17.0596 9.5 17.75ZM18 17.75C18 18.4404 17.4404 19 16.75 19C16.0596 19 15.5 18.4404 15.5 17.75C15.5 17.0596 16.0596 16.5 16.75 16.5C17.4404 16.5 18 17.0596 18 17.75Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
