<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.51652 3.89627C4.88688 3.3366 5.51326 3 6.18438 3H17.8155C18.4866 3 19.113 3.3366 19.4834 3.89627L21.8248 7.43438C21.9353 7.59525 22 7.79007 22 8V10C22 11.3106 21.1343 12.3294 20 12.7668V21H4V12.7668C2.86574 12.3294 2 11.3105 2 10V8C2 7.79221 2.06338 7.59922 2.17186 7.4393L4.51652 3.89627ZM4 9V10C4 10.4448 4.44566 11 5.25 11C6.05434 11 6.5 10.4448 6.5 10H8.5C8.5 10.4448 8.94566 11 9.75 11C10.5543 11 11 10.4448 11 10H13C13 10.4448 13.4457 11 14.25 11C15.0543 11 15.5 10.4448 15.5 10H17.5C17.5 10.4448 17.9457 11 18.75 11C19.5543 11 20 10.4448 20 10V9H4ZM19.139 7H4.86085L6.18438 5H17.8155L19.139 7ZM18 12.9176C17.4396 12.7935 16.9192 12.5331 16.5 12.1663C15.8982 12.6929 15.088 13 14.25 13C13.412 13 12.6018 12.6929 12 12.1663C11.3982 12.6929 10.588 13 9.75 13C8.91202 13 8.10184 12.6929 7.5 12.1663C7.08076 12.5331 6.56041 12.7935 6 12.9176V19H9L9 14H15V19H18V12.9176ZM13 16V19H11V16H13Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
